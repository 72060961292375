import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`PayPerks was recently contacted by `}<a parentName="p" {...{
        "href": "http://www.foxbusiness.com/archive/author/cindy-vanegas/index.html"
      }}>{`Cindy Vanegas`}</a>{` from `}<a parentName="p" {...{
        "href": "http://www.foxbusiness.com/index.html"
      }}>{`Fox Business News`}</a>{` for their ongoing series on startups that were started during business school. Fox interviewed our CEO, Arlyn Davich, who started working on the idea for PayPerks while studying at Columbia Business School.`}</p>
    <p>{`The article is a series of 6 slides in a `}<a parentName="p" {...{
        "href": "http://smallbusiness.foxbusiness.com/slideshow/sbc/2011/12/06/from-business-school-to-startup-payperks/#slide=1"
      }}>{`slide show`}</a>{`.`}</p>
    <p>{`While there are no captions on the images, the second image is of me, Jake Peters, and the fouth image is of Arlyn herself. Congratulations, Arlyn!`}</p>
    <p>{`The text of the article is posted below, if you can't see the `}<a parentName="p" {...{
        "href": "http://smallbusiness.foxbusiness.com/slideshow/sbc/2011/12/06/from-business-school-to-startup-payperks/#slide=1"
      }}>{`slide show`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "362px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "17.955801104972377%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA/0lEQVQI1wH0AAv/AJWQeXh1ZISBbYF+bH99a1xaT0VHSHBxc3V2eFpbXEpLTTk6PD9AQ4CAgWFiY2RkZmttbnh4emVmZ2lqawC6nGScg1SukVu8nGG/n2RyYUI8PT+hoqLCw8OkpKVwcHJNTlBKSkzExMR+f4B5enu1tbbAwMFcXV57fH0Aa1k6aVY4g2g+fGQ+h2xCZVAwNjY2VFRWSkpLT1BQVlZXR0dIOTk6X19gTU5PU1RVUFFSYGFiR0hJV1dYAFNCKks8J01AK0w/KUs+KT8yHx8eHhAREggICR4fIAwMDRQUFSUlJgQEBRMTFBEREwcHCBwcHRISEwgICukXUfBT+1ATAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/c424bf784604cf340eb47b419f55fe3f/c2de8/fbnlogo.webp 362w"],
            "sizes": "(max-width: 362px) 100vw, 362px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/c424bf784604cf340eb47b419f55fe3f/10600/fbnlogo.png 362w"],
            "sizes": "(max-width: 362px) 100vw, 362px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/c424bf784604cf340eb47b419f55fe3f/10600/fbnlogo.png",
            "alt": "fbnlogo",
            "title": "fbnlogo",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`From Business School to Startup: PayPerks`}</strong></p>
      <p parentName="blockquote"><em parentName="p">{`By Cindy Vanegas`}</em></p>
      <p parentName="blockquote">{`From frequent flier miles to credit card points, the idea of spending more to earn more rewards has become the norm. But the opposite philosophy is what led Arlyn Davich to launch a small business that rewards people for saving.`}</p>
      <p parentName="blockquote">{`Arlyn Davich had been working in marketing and public relations prior to enrolling at Columbia Business School, where she stumbled upon the idea for PayPerks. Davich has received two rounds of funding and partnered with a prominent payment processor. She recently shared her story with FOX Business:`}</p>
      <p parentName="blockquote"><strong parentName="p">{`FBN: What is PayPerks?`}</strong>{`
PayPerks is an educational rewards platform for the financially underserved. Its patent-pending rewards model that fuses education with sweepstakes-based incentives in order to fuel positive behavior changes among low and middle-income people. PayPerks users can earn PayPerks points through a variety of actions like taking PayPerks’ online educational curriculum, lowering their check cashing fees or accumulating a rainy day fund in a prepaid account. Each point earned on PayPerks is automatically converted into the chance to win a cash prize. PayPerks gives away dozens of cash prizes each month ranging from $25 to $5,000. We work with employers to help them realize cost and environmental savings by issuing fewer paper checks. Our customers are banks and issuers of prepaid debit cards. They pay us to educate cardholders.`}</p>
      <p parentName="blockquote"><strong parentName="p">{`FBN: How did PayPerks originate?`}</strong>{`
I was toying with the idea of putting coupons on electronic paychecks. I went to the American Payroll Association's Annual Conference and I realized that a third of Americans still get paper paychecks because they don’t have bank accounts and can’t use direct deposit. So my first insight was that people still get printed checks. My second insight was that the alternative is for them to get direct deposit on to a prepaid card. These cards are so much better I wondered why would anyone want a printed check. The barrier to a prepaid debit card, however, is they did not understand the benefits.`}</p>
      <p parentName="blockquote"><strong parentName="p">{`FBN: What steps did you take to build PayPerks?`}</strong>{`
I was in the The Entrepreneurial Greenhouse Program. In that program you write a business plan and work on an investor pitch. I probably gave 50 to 75 pitches in the second half of my second year. Through the Eugene Lang Entrepreneurship Center, the school invests in a handful of businesses each year, and mine was selected. With money from Columbia, I started to build toward developing a test pilot. At graduation in 2009, I had written the business plan and had one employer in the pilot program. I spent the next year after graduation getting that pilot off the group and doing more research into the market and customers. I traveled to payroll conferences and met with employers; it was the epitome of customer-centric development. We closed our seed round in October 2010 and then launched a pilot in April 2011 through a partnership with MasterCard and Comdata. We used the pilot to get more customers and are now raising another round of financing in order to scale the business.`}</p>
      <p parentName="blockquote"><strong parentName="p">{`FBN: How did your business school experience help you create a successful company?`}</strong>{`
I wanted to start a business and I really understood marketing, but not the other aspects of running a business. In hindsight, I don’t think I would have started a business had I not gone to business school. I would not have had the confidence to start something completely new without a safety net. There is the “hard” and “soft” education in business school. I met my business partner, Jake Peters, who is the co-founder and chief tech officer, through a friend of mine at Columbia. The connections helped me get in touch with investors. The classes taught me how to pitch, and I learned how to do entrepreneurial sales. A lot of how I got MasterCard on board was by using lessons I learned from a class. I learned how to build a financial model in Excel and how to build a cash flow statement. Before business school I didn't even know how to use Excel--now I know enough to be dangerous.`}</p>
      <p parentName="blockquote"><strong parentName="p">{`FBN: How will PayPerks grow in the coming year?`}</strong>{`
Since launching the pilot we have had our most explosive growth. Once the pilot was done we had results that propelled growth and we shared those results with potential customers and generated a lot of excitement. Because of customer interest, we were able to generate investor interest. We are looking to educate more people and reach them through employers and through government channels. We will be launching in those channels in 2012 and are building a team in New York. We have a team of software developers offshore, but are hiring developers and marketing and operations people for the New York office.`}</p>
    </blockquote>
    <p>{`The URL for the article is:
`}<a parentName="p" {...{
        "href": "http://smallbusiness.foxbusiness.com/slideshow/sbc/2011/12/06/from-business-school-to-startup-payperks/"
      }}>{`http://smallbusiness.foxbusiness.com/slideshow/sbc/2011/12/06/from-business-school-to-startup-payperks/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      